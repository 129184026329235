<template>
    <div class="invite-code-manager">
         <Card :padding="10" shadow title="查询条件" class="bt-card">
            <div class="search-con search-con-top">
                <Input v-model.trim="key" clearable @on-enter="searchInviteCodeList" @on-clear="searchInviteCodeList" placeholder="邀请码" class="search-input"/>
                <Button type="primary" icon="md-search" @click="searchInviteCodeList">搜索</Button>
                <Select placeholder="选择状态" v-model="useStatus" :clearable="true" @on-change="useStatusChange" style="width: 150px;">
                    <Option :value="0">未使用</Option>
                    <Option :value="1">已使用</Option>
                </Select>
                <Button type="warning" @click="exportInviteCodeList">导出</Button>
            </div>
        </Card>
        
        <Card :padding="10" title="邀请码列表" shadow>
            <p slot="extra">
               <Button @click="handleAddInviteCode" type="primary">创建邀请码</Button>
            </p>
            <Table :columns="columns" border :data="list" stripe></Table>
            <Page :total="total" :current="page" :page-size="size" @on-change="handlePageChange" @on-page-size-change='handlePageSize' show-total show-elevator show-sizer class-name="bt-page"/>
        </Card>

        <Modal
            v-model="modal"
            title="生成邀请码">
            <Form ref="createInviteCode" :model="createInviteCode" :label-width="100">
                <FormItem label="数量" prop="count" :rules="{required: true, min: 1,type: 'number',message: '数量不能为空', trigger: 'change'}">
                   <InputNumber v-model="createInviteCode.count" placeholder="请输入数量"></InputNumber>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button type="text" size="large" @click="modal=false">取消</Button>
                <Button type="primary" size="large" @click="handleInviteCodeSubmit">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import {formatDate} from '@/utils';

export default {
    name: 'inviteCodeManager',
    data(){
        return {
            key: '',
            useStatus: -1,
            list: [],
            page: 1,
            size: 20,
            total: 1,
            columns:[{
              key: 'code',
              title: '邀请码'
           },{
              key: 'status',
              title: '使用状态',
              render: (h,{row})=>{
                    if(row.uid == 0){
                        return h('Tag',{props: {color: 'primary'}},'未使用');
                    }else {
                        return h('Tag',{props: {color: 'success'}},'已使用');
                    }
              }
           },{
              key: 'uid',
              title: '使用用户ID',
              align: 'center',
              render: (h,{row})=>{
                  if(row.uid > 0){
                      return h('span',row.uid);
                  }
              }
           },{
              key: 'createTime',
              title: '创建时间',
              align: 'center',
              render: (h,{row})=>{
                  return h('span',formatDate(row.createTime,'yyyy-MM-dd hh:mm:ss'));
              }
           },{
              key: 'modifyTime',
              title: '修改时间',
              align: 'center',
              render: (h,{row})=>{
                  return h('span',formatDate(row.modifyTime,'yyyy-MM-dd hh:mm:ss'));
              }
           },{
              key: 'option',
              title: '操作',
              render: (h, {row,index}) => {
                 let data = [];
                 if(row.uid == 0){
                     data.push(h('Button',{
                        props: {type: 'error',size: 'small'},
                        style: {marginRight: '5px'},
                        on: {
                            click: () => {
                                this.handleDeleteInviteCode(index);
                            }
                        }
                    }, "删除"));
                 }
                 return h('div', data);
              }
            }],
            modal: false,
            createInviteCode: {
                count: 1
            }
        }
    },
    created(){
        this.searchInviteCodeList();
    },
    methods: {
        useStatusChange(){
            this.searchInviteCodeList();
        },
        searchInviteCodeList(){
            this.page = 1;
            this.queryInviteCodeList();
        },
        queryInviteCodeList(){
            this.$http.post('/api/admin/getInviteCodeList',{
                key: this.key,
                useStatus: this.useStatus,
                pn: this.page,
                ps: this.size
            }).then(data=>{
                this.list = data.list;
                this.size = data.pageSize;
                this.total = data.total;
            });
        },
        handleAddInviteCode(){
            this.createInviteCode.count = 1;
            this.modal = true;
        },
        handleInviteCodeSubmit(){
            this.$refs['createInviteCode'].validate((valid) => {
              if(valid) {
                 let inviteCode = Object.assign({},this.createInviteCode);
                 this.$http.post('/api/admin/createInviteCode',inviteCode).then(()=>{
                    this.$Message.success("操作成功");
                    this.queryInviteCodeList();
                    this.modal = false;
                 });
              }
           });
        },
        handleDeleteInviteCode(index){
            this.$Modal.confirm({
              title: '删除操作',
              content: '确认删除该邀请码吗？',
              onOk: () => {
                 let inviteCode = this.list[index];
                 this.$http.post('api/admin/deleteInviteCode',{id: inviteCode.id}).then(()=>{
                    this.$Message.success("删除成功");
                    this.queryInviteCodeList();
                 });
              }
           });
        },
        exportInviteCodeList(){
           this.$http.download('/api/admin/exportInviteCodeList',{
               key: this.key,
               useStatus: this.useStatus
           });
        },
        handlePageChange(page){
           this.page = page;
           this.queryInviteCodeList();
        },
        handlePageSize(size){
           this.size = size;
           this.page = 1;
           this.queryInviteCodeList();
        }
    }
}
</script>