<template>
    <div class="tech-consult-manager">
         <Card :padding="10" shadow title="查询条件" class="bt-card">
            <div class="search-con search-con-top">
                <Input v-model.trim="key" clearable @on-enter="searchTechConsultList" @on-clear="searchTechConsultList" placeholder="名称" class="search-input"/>
                <Button type="primary" icon="md-search" @click="searchTechConsultList">搜索</Button>
            </div>
        </Card>
        
        <Card :padding="10" :title="routeTitle + '列表'" shadow>
            <p slot="extra">
               <Button @click="handleAddTechConsult" type="primary">新增</Button>
            </p>
            <Table :columns="columns" border :data="list" stripe></Table>
            <Page :total="total" :current="page" :page-size="size" @on-change="handlePageChange" @on-page-size-change='handlePageSize' show-total show-elevator show-sizer class-name="bt-page"/>
        </Card>

        <Modal
            v-model="modal"
            :title="(editTechConsult.id == 0 ? '新增' : '编辑') + routeTitle"
            @on-visible-change="resetForm"
            :width="600">
            <Form ref="editTechConsult" :model="editTechConsult" :label-width="100">
                <FormItem label="类型" prop="ctype" :rules="{required: true, message: '类型不能为空'}">
                    <Select v-model="editTechConsult.ctype">
                        <Option :value="0">政策</Option>
                        <Option :value="1">资讯</Option>
                    </Select>
                </FormItem>
                <FormItem label="标题" prop="title" :rules="{required: true, message: '标题不能为空'}">
                   <Input v-model.trim="editTechConsult.title" placeholder="请输入标题"></Input>
                </FormItem>
                <FormItem label="内容" prop="content" :rules="{required: true, message: '内容不能为空'}">
                    <editor v-model="editTechConsult.content"></editor>
                </FormItem>
                <FormItem label="排序码" prop="orders" :rules="{required: true, min: 0,type: 'number',message: '排序码不能为空', trigger: 'change'}">
                   <InputNumber v-model="editTechConsult.orders" placeholder="请输入排序码"></InputNumber>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button type="text" size="large" @click="modal=false">取消</Button>
                <Button type="primary" size="large" @click="handleTechConsultSubmit">确定</Button>
            </div>
        </Modal>

        <Modal
            v-model="auditBackModal"
            title="审核打回">
            <Form ref="auditBackValue" :model="auditBackValue" :label-width="100">
                <FormItem label="原因" prop="reason" :rules="{required: true, message: '原因不能为空'}">
                   <Input v-model.trim="auditBackValue.reason" maxlength="500" show-word-limit type="textarea" placeholder="请输入原因"></Input>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button type="text" size="large" @click="auditBackModal=false">取消</Button>
                <Button type="primary" size="large" @click="handleAuditBackSubmit">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import {formatDate,renderShowImage} from '@/utils';
import editor from '@/components/common/Editor';

export default {
    name: 'techConsultManager',
    data(){
        return {
            key: '',
            list: [],
            page: 1,
            size: 20,
            total: 1,
            columns:[{
              key: 'id',
              title: 'ID',
              align: 'center'
           },{
              key: 'ctype',
              title: '类型',
              render: (h,{row,index})=>{
                if(row.ctype == 0){
                    return h('Tag',{props: {color: 'primary'}},'政策');
                }else if(row.ctype == 1){
                    return h('Tag',{props: {color: 'error'}},'资讯');
                }
             }
           },{
              key: 'title',
              title: '标题',
              tooltip: true
           },{
              key: 'orders',
              title: '排序码',
              align: 'center'
           },{
              key: 'status',
              title: '审核状态',
              align: 'center',
              render: (h,{row,index})=>{
                if(row.status == 0){
                    return h('Tag',{props: {color: 'default'}},'待审核');
                }else if(row.status == 1){
                    return h('Tag',{props: {color: 'primary'}},'审核通过');
                }else {
                    return h('Tag',{props: {color: 'error'},
                        nativeOn: {
                            click: () => {
                                this.lookAuditBackReason(index);
                            }
                        }
                    },'审核失败');
                }
             }
           },{
              key: 'createTime',
              title: '创建时间',
              align: 'center',
              render: (h,{row})=>{
                  return h('span',formatDate(row.createTime,'yyyy-MM-dd hh:mm:ss'));
              }
           },{
              key: 'modifyTime',
              title: '修改时间',
              align: 'center',
              render: (h,{row})=>{
                  return h('span',formatDate(row.modifyTime,'yyyy-MM-dd hh:mm:ss'));
              }
           },{
              key: 'option',
              title: '操作',
              minWidth: 120,
              render: (h, {row,index}) => {
                 let data = [];
                 if(row.status != 1){
                     data.push(h('Button',{
                        props: {type: 'info',size: 'small'},
                        style: {marginRight: '5px'},
                        on: {
                            click: () => {
                                this.handleEditTechConsultStatus(index,1);
                            }
                        },
                        directives:[{
                            name: 'permission',
                            value: 'techConsult:audit'
                        }]
                    }, "审核通过"));
                 }
                 if(row.status != 2){
                    data.push(h('Button',{
                        props: {type: 'error',size: 'small'},
                        style: {marginRight: '5px'},
                        on: {
                            click: () => {
                                this.handleEditTechConsultStatus(index,2);
                            }
                        },
                        directives:[{
                            name: 'permission',
                            value: 'techConsult:audit'
                        }]
                    }, "审核打回"));
                 }
                 data.push(h('Button',{
                        props: {type: 'success',size: 'small'},
                        style: {marginRight: '5px'},
                        on: {
                            click: () => {
                                this.handleEditTechConsult(index);
                            }
                        }
                    }, "编辑"));
                 data.push(h('Button',{
                        props: {type: 'error',size: 'small'},
                        style: {marginRight: '5px'},
                        on: {
                            click: () => {
                                this.handleDeleteTechConsult(index);
                            }
                        }
                    }, "删除"));
                 return h('div', data);
              }
            }],
            modal: false,
            editTechConsult: {
               id: 0,
               title: '',
               content: '',
               thumbnail: '',
               orders: 0,
               status: 0
            },
            auditBackModal: false,
            auditBackValue: {
                index: 0,
                status: 0,
                reason: ''
            }
        }
    },
    created(){
        this.searchTechConsultList();
    },
    methods: {
        searchTechConsultList(){
            this.page = 1;
            this.queryTechConsultList();
        },
        queryTechConsultList(){
            this.$http.post('/api/admin/getTechConsultList',{
                key: this.key,
                pn: this.page,
                ps: this.size
            }).then(data=>{
                this.list = data.list;
                this.size = data.pageSize;
                this.total = data.total;
            });
        },
        handleAddTechConsult(){
            this.editTechConsult.id = 0;
            this.modal = true;
        },
        handleEditTechConsult(index){
            this.editTechConsult = Object.assign({},this.list[index]);
            this.modal = true;
        },
        handleTechConsultSubmit(){
            this.$refs['editTechConsult'].validate((valid) => {
              if(valid) {
                 let techConsult = Object.assign({},this.editTechConsult);
                 this.$http.post('/api/admin/saveTechConsult',techConsult).then(()=>{
                    this.$Message.success("操作成功");
                    this.queryTechConsultList();
                    this.modal = false;
                 });
              }
           });
        },
        openAuditBack(id,status){
           this.auditBackValue = {
               id: id,
               status: status,
               reason: ''
           };
           this.auditBackModal = true;
           this.$refs['auditBackValue'].resetFields();
        },
        handleAuditBackSubmit(){
            this.$refs['auditBackValue'].validate((valid) => {
              if(valid) {
                  this.$http.post('api/admin/updateTechConsultStatus',this.auditBackValue).then(()=>{
                      this.$Message.success("审核打回成功");
                      this.auditBackModal = false;
                      this.queryTechConsultList();
                  });
              }
           });
        },
        handleEditTechConsultStatus(index,status){
            let techConsult = this.list[index];
            if(status == 1){
                this.$Modal.confirm({
                    title: '审核操作',
                    content: '确认审核通过吗？',
                    onOk: () => {
                        this.$http.post('api/admin/updateTechConsultStatus',{id: techConsult.id,status: 1}).then(()=>{
                            this.$Message.success("审核通过成功");
                            this.queryTechConsultList();
                        });
                    }
                });
            }else if(status == 2){
                this.openAuditBack(techConsult.id,status);
            }else {
                this.$Message.error("无效状态");
            }
        },
        handleDeleteTechConsult(index){
            this.$Modal.confirm({
              title: '删除操作',
              content: '确认删除该科技咨询吗？',
              onOk: () => {
                 let techConsult = this.list[index];
                 this.$http.post('api/admin/deleteTechConsult',{id: techConsult.id}).then(()=>{
                    this.$Message.success("删除成功");
                    this.queryTechConsultList();
                 });
              }
           });
        },
        lookAuditBackReason(index){
            this.$http.post('api/admin/queryAuditLogReason',{itemId: this.list[index].id,itemType: 6},false).then((data)=>{
                this.$Modal.error({
                    title: '打回原因',
                    content: data
                });
            });
        },
        resetForm(show){
           if(!show) {
              this.$refs.editTechConsult.resetFields();
              this.editTechConsult.id = 0;
           }
        },
        handlePageChange(page){
           this.page = page;
           this.queryTechConsultList();
        },
        handlePageSize(size){
           this.size = size;
           this.page = 1;
           this.queryTechConsultList();
        }
    },
    components: {editor}
}
</script>