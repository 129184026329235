<template>
    <div class="config-manager">
        <Card :padding="10" title="配置管理" shadow>
            <Form ref="editConfig" :model="editConfig" :label-width="150">
                <FormItem label="开启企业入驻" prop="showEnterprisePortal">
                    <i-switch v-model="editConfig.showEnterprisePortal" :true-value="1" :false-value="0"/>
                </FormItem>
                <FormItem>
                    <Button type="primary" size="large" @click="handleConfigSubmit">保存</Button>
                </FormItem>
            </Form>
        </Card>
    </div>
</template>
<script>
export default {
    name: 'sysConfigManager',
    data(){
        return {
            editConfig: {
               showEnterprisePortal: 0
            }
        }
    },
    created(){
        this.getConfig();
    },
    methods: {
        getConfig(){
            this.$http.post('/api/admin/getSysConfig').then((data)=>{
                this.editConfig = data;
            });
        },
        handleConfigSubmit(){
            this.$refs['editConfig'].validate((valid) => {
              if(valid) {
                 let config = Object.assign({},this.editConfig);
                 this.$http.post('/api/admin/updateSysConfig',config).then(()=>{
                    this.$Message.success("操作成功");
                    this.getConfig();
                 });
              }
            });
        }
    }
}
</script>