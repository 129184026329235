<template>
  <div class="notice-manager">
    <Card :padding="10" title="编辑企业信息" shadow>
      <Form ref="formValidate" :model="formValidate" :label-width="120">
        <FormItem label="企业名称" prop="name" :rules="{required: true,message:'必填，且长度不能超过20个字',max:20}">
          <Input v-model="formValidate.name" placeholder="企业名称"></Input>
        </FormItem>
        <FormItem label="信用代码" prop="code" :rules="{required: true,message:'必填，且长度不能超过20个字',max:20}">
          <Input v-model="formValidate.code" placeholder="统一社会信用代码"></Input>
        </FormItem>
        <FormItem label="注册地址" prop="registerAddress" :rules="{required: true,message:'必填，且长度不能超过50个字',max:50}">
          <Input v-model="formValidate.registerAddress" placeholder="企业注册地址"></Input>
        </FormItem>
        <FormItem label="办公地址" prop="address" :rules="{required: false,message:'必填，且长度不能超过50个字',max:50}">
          <Input v-model="formValidate.address" placeholder="企业实际办公地址"></Input>
        </FormItem>
        <FormItem label="所属街道(园区)" prop="sid" :rules="{required: true,message:'必填'}">
          <Select v-model="formValidate.sid" placeholder="请选择街道">
            <Option v-for="item in streetList" :key="item.id" :value="item.id">{{item.name}}</Option>
          </Select>
        </FormItem>
        <FormItem label="行业领域" prop="industry" :rules="{required: false,message:'必填，且长度不能超过20个字',max:20}">
          <AutoComplete
              v-model="formValidate.industry"
              :data="industryList"
              placeholder="行业领域" />
        </FormItem>
        <FormItem label="企业资质" prop="qualification" :rules="{required: false,message:'必填，且长度不能超过20个字',max:20}">
          <AutoComplete
              v-model="formValidate.qualification"
              :data="qList"
              placeholder="企业资质" />
        </FormItem>
        <FormItem label="承担项目" prop="undertakeProject" :rules="{required: false,message:'必填，且长度不能超过20个字',max:20}">
          <Input v-model="formValidate.undertakeProject" placeholder="XXX省级项目、XXX市级项目"></Input>
        </FormItem>
        <FormItem label="企业类型" prop="etype" :rules="{required: true,message:'必填'}">
          <Select v-model="formValidate.etype" placeholder="企业类型">
            <Option v-for="item in typeList" :key="item" :value="item">{{item}}</Option>
          </Select>
        </FormItem>
        <FormItem label="营业收入" prop="income2021" :rules="{required: false,message:'必填'}">
          <Select v-model="formValidate.income2021" placeholder="营业收入">
            <Option v-for="item in feeList" :key="item" :value="item">{{item}}</Option>
          </Select>
        </FormItem>
        <FormItem label="研发投入" prop="putin2021" :rules="{required: false,message:'必填'}">
          <Select v-model="formValidate.putin2021" placeholder="研发投入">
            <Option v-for="item in feeList" :key="item" :value="item">{{item}}</Option>
          </Select>
        </FormItem>
        <FormItem label="核心产品简介" prop="productIntroduce" :rules="{required: false,message:'必填'}">
          <editor v-model="formValidate.productIntroduce"></editor>
        </FormItem>
        <FormItem label="企业技术需求" prop="requirements" :rules="{required: false,message:'必填且长度不能超过500个字',max:500}">
          <Input type="textarea" v-model="formValidate.requirements" placeholder="企业技术需求"></Input>
        </FormItem>
        <FormItem label="联系人名字" prop="contactName" :rules="{required: false,message:'必填，且长度不能超过10个字',max:10}">
          <Input v-model="formValidate.contactName" placeholder="联系人名字"></Input>
        </FormItem>
        <FormItem label="联系人职位" prop="contactJob" :rules="{required: false,message:'必填，且长度不能超过10个字',max:10}">
          <Input v-model="formValidate.contactJob" placeholder="联系人职位"></Input>
        </FormItem>
        <FormItem label="联系人手机号" prop="contactPhone" :rules="{required: false,message:'必填，且长度不能超过11个字',max:11}">
          <Input v-model="formValidate.contactPhone" placeholder="联系人手机号"></Input>
        </FormItem>
        <FormItem label="缩略图" prop="thumbnail">
            <CustomUpload v-model="formValidate.thumbnail"></CustomUpload>
        </FormItem>
        <FormItem label="图片" prop="mainPic" :rules="{required: false, message: '图片不能为空'}">
            <CustomUpload v-model="formValidate.mainPic"></CustomUpload>
        </FormItem>
        <FormItem>
          <Button type="primary" @click="handleSubmit('formValidate')" :disabled="showNotEdit">保存信息</Button>
        </FormItem>
      </Form>
    </Card>

  </div>
</template>

<script>
import {renderShowImage, formatDate} from '@/utils';
import editor from '@/components/common/Editor';

export default {
  name: 'addEnterpriseManager',
  data() {
    return {
      showNotEdit:false,
      industryList:["电子信息","生物与新医药","航空航天","新材料","高技术服务","新能源与节能","资源与环境","先进制造与自动化","其他"],
      qList:["高新技术企业","江苏省工程技术研究中心"],
      feeList:["100万元以下","100万元-500万元","500万元-1000万元","1000万元-1500万元","1500万元-2000万元","2000万元以上"],
      typeList:['科技型中小企业','瞪羚技术企业','高新技术企业','培育独角兽技术企业','独角兽技术企业'],
      streetList:[],
      formValidate:{}
    }
  },
  components:{
    editor
  },
  created() {
    this.getStreetList();
    this.getEnterpirse();
  },
  methods: {
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$http.post('/api/enterprise/save',this.formValidate).then(data=>{
            this.$Message.success('保存成功');
          });
        }
      })
    },
    getEnterpirse(){
      this.$http.get('/api/enterprise/getEnterprise',{
      }).then(data=>{
        if(data!=null){
          this.formValidate=data;
          this.formValidate.industry=data.industry==null?'':data.industry;
          this.formValidate.qualification=data.qualification==null?'':data.qualification;
        }else{
          this.showNotEdit=true;
          this.$Modal.error({title:'您不是企业用户，无法编辑企业信息！'})
        }
      });
    },
    getStreetList(){
      this.$http.get('/api/other/getStreetList',{
      }).then(data=>{
        this.streetList = data;
      });
    }
  }
}
</script>