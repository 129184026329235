<template>
    <div class="custom-upload">
        <Upload
            :action="uploadPath"
            :before-upload="handleUpload"
            :on-success="handleUploadSuccess"
            :on-remove="handleRemove"
            :on-error="handleError"
            :on-format-error="handleFormatError"
            :format="format"
            :show-upload-list="showUploadList">
            <Button icon="ios-cloud-upload-outline" :loading="loadingStatus">{{title}}</Button>
        </Upload>
        <img v-if="showPic && !!pic" class="upload_media" :src="pic" />
    </div>
</template>
<script>
import {baseUrl} from '@/api/fetch';

export default {
    props: {
        value: String,
        title: {
            type: String,
            default: '上传图片'
        },
        showPic: {
            type: Boolean,
            default: true
        },
        format: {
            type: Array,
            default: ()=>{
                return ['jpg','jpeg','png'];
            }
        },
        showUploadList: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            pic: '',
            uploadPath: baseUrl + '/api/common/uploadFile',
            loadingStatus: false
        }
    },
    methods: {
        handleUploadSuccess(resp) {
            if(resp.code == 200){
                this.$emit('input',resp.data);
            }else {
                this.$Message.error({
                    content: resp.msg || "上传失败！",
                    duration: 3
                });
            }
            this.loadingStatus = false;
        },
        handleRemove() {
            this.$emit('input', '');
        },
        handleError() {
            this.$Message.error("上传失败！");
            this.loadingStatus = false;
        },
        handleFormatError(){
            this.$Message.error("格式不支持！");
            this.loadingStatus = false;
        },
        handleUpload(){
            this.loadingStatus = true;
        }
    },
    watch: {
        value: {
            immediate: true,
            handler:function(val){
               this.pic = val;
            }
        }
    }
}
</script>
<style scoped>
.upload_media{
    min-height: 70px;
    max-width: 50px;
    background: rgb(0,0,0,0.02);
    padding: 0px;
}
</style>
