<template>
    <div class="invite-code-manager">
         <Card :padding="10" shadow title="查询条件" class="bt-card">
            <div class="search-con search-con-top">
                <Input v-model.trim="key" clearable @on-enter="searchAuditLogList" @on-clear="searchAuditLogList" placeholder="审核人姓名 / 审核类型" class="search-input"/>
                <Button type="primary" icon="md-search" @click="searchAuditLogList">搜索</Button>
            </div>
        </Card>
        
        <Card :padding="10" title="审核日志列表" shadow>
            <Table :columns="columns" border :data="list" stripe></Table>
            <Page :total="total" :current="page" :page-size="size" @on-change="handlePageChange" @on-page-size-change='handlePageSize' show-total show-elevator show-sizer class-name="bt-page"/>
        </Card>
    </div>
</template>

<script>
import {formatDate} from '@/utils';

export default {
    name: 'auditLogManager',
    data(){
        return {
            key: '',
            list: [],
            page: 1,
            size: 20,
            total: 1,
            columns:[{
              key: 'uid',
              title: '审核人ID',
              tooltip: true
           },{
              key: 'userName',
              title: '审核人姓名',
              align: 'center'
           },{
              key: 'itemTypeText',
              title: '审核板块类型',
              align: 'center'
           },{
              key: 'auditStatus',
              title: '审核状态',
              align: 'center',
              render: (h,{row})=>{
                if(row.auditStatus == 1){
                    return h('Tag',{props: {color: 'primary'}},'审核通过');
                }else {
                    return h('Tag',{props: {color: 'error'}},'审核失败');
                }
             }
           },{
              key: 'auditReason',
              title: '失败原因',
              align: 'center',
              tooltip: true
           },{
              key: 'createTime',
              title: '审核时间',
              align: 'center',
              render: (h,{row})=>{
                  return h('span',formatDate(row.createTime,'yyyy-MM-dd hh:mm:ss'));
              }
           }]
        }
    },
    created(){
        this.searchAuditLogList();
    },
    methods: {
        searchAuditLogList(){
            this.page = 1;
            this.queryAuditLogList();
        },
        queryAuditLogList(){
            this.$http.post('/api/admin/getAuditLogList',{
                key: this.key,
                pn: this.page,
                ps: this.size
            }).then(data=>{
                this.list = data.list;
                this.size = data.pageSize;
                this.total = data.total;
            });
        },
        handlePageChange(page){
           this.page = page;
           this.queryAuditLogList();
        },
        handlePageSize(size){
           this.size = size;
           this.page = 1;
           this.queryAuditLogList();
        }
    }
}
</script>