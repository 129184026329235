<template>
  <div class="notice-manager">
    <Card :padding="10" title="菜单列表" shadow>
      <Table row-key="id" :columns="columns" border :data="list" stripe></Table>
    </Card>
  </div>
</template>

<script>
import {renderShowImage,formatDate} from '@/utils';
import editor from '@/components/common/Editor';
import {baseUrl} from '@/api/fetch';
import {getToken} from "@/utils";

export default {
  name: 'menuMain',
  data(){
    return {
      key: '',
      type: -1,
      list: [],
      page: 1,
      size: 20,
      total: 1,
      columns:[{
        key: 'id',
        title: 'ID',
        tree: true,
        align: 'center'
      }, {
        key: 'path',
        title: '路径',
      }, {
        key: 'name',
        title: '名称',
        align: 'center'
      }, {
        key: 'icon',
        title: '图标',
        align: 'center'
      }, {
        key: 'title',
        title: '标题',
        align: 'center'
      }, {
        key: 'orderCode',
        title: '排序码',
        align: 'center'
      }, {
        key: 'permission',
        title: '权限',
        align: 'center'
      }, {
        key: 'component',
        title: '组件',
        align: 'center'
      }, {
        key: 'type',
        title: '类型',
        align: 'center',
        render: (h, {row,column,index}) => {
          let data = '';
          if(row.type==1){
            data='菜单';
          }else{
            data='权限';
          }
          return h('div',data);
        }
      }]
    }
  },
  created(){
    this.queryList();
  },
  methods: {
    queryList(){
      this.$http.get('/api/other/getSysMenus',{
        key: this.key
      }).then(data=>{
        console.log(data)
        this.list = data;
      });
    }
  }
}
</script>