<template>
  <div id="editor">
    <div ref="editorElem" style="text-align:left"></div>
  </div>
</template>
<script>
  import E from 'wangeditor'
  import {getToken} from "@/utils"
  import {baseUrl} from '@/api/fetch'

  const uploadPath = baseUrl + '/api/common/uploadFile';
  const token = getToken();

  export default {
    name: 'editor',
    props: {
        value: {
           type: String,
           default: ''
        }
    },
    data () {
      return {
        editor: null,
        editorContent: ''
      }
    },
    watch: {
      value(value) {
         if(value != this.editorContent){
            this.setHtml(value);
         }
      }
    },
    methods: {
      setHtml(value) {
        if(value==null){
            this.editorContent = '';
            this.editor.txt.html('')
        }else{
            this.editorContent = value;
            this.editor.txt.html(value)
        }
        
      }
    },
    mounted () {
      this.editor = new E(this.$refs.editorElem)
      this.editor.customConfig = this.editor.customConfig ? this.editor.customConfig : this.editor.config;
      this.editor.customConfig.onchange = (html) => {
        this.editorContent = html;
        this.$emit("input",this.editorContent);
      }
      this.editor.customConfig.uploadImgServer = uploadPath
      this.editor.customConfig.uploadVideoServer=uploadPath
      this.editor.customConfig.uploadFileName = 'file'
      this.editor.customConfig.uploadVideoName = 'file'
      this.editor.customConfig.withCredentials = true
      this.editor.customConfig.zIndex = 100
      this.editor.customConfig.uploadVideoHeaders = {
        'Authorization' : 'Bearer '+token
      }
      this.editor.customConfig.uploadImgHeaders = {
        'Authorization' : 'Bearer '+token
      }
      this.editor.customConfig.menus = [          // 菜单配置
        'head',  // 标题
        'bold',  // 粗体
        'fontSize',  // 字号
        'fontName',  // 字体
        'italic',  // 斜体
        'underline',  // 下划线
        'foreColor',  // 文字颜色
        'backColor',  // 背景颜色
        'link',  // 插入链接
        'justify',  // 对齐方式
        'quote',  // 引用
        'emoticon',  // 表情
        'image',  // 插入图片
        'video',//插入视频
      ]
      // 下面是最重要的的方法
      this.editor.customConfig.uploadImgHooks = {
         customInsert: function (insertImg, result, editor) {
            // 图片上传并返回结果，自定义插入图片的事件（而不是编辑器自动插入图片！！！）
            // insertImg 是插入图片的函数，editor 是编辑器对象，result 是服务器端返回的结果
            // 举例：假如上传图片成功后，服务器端返回的是 {url:'....'} 这种格式，即可这样插入图片：
            insertImg(result.data);
            // result 必须是一个 JSON 格式字符串！！！否则报错
         }
      }
      this.editor.customConfig.uploadVideoHooks = {
        customInsert: function (insertVideoFn, result, editor) {
          insertVideoFn(result.data);
        }
      }
      this.editor.create();     // 创建富文本实例
      this.setHtml(this.value);
    }
  }
</script>
<style>
  #editor div:not(.w-e-full-screen-editor) .w-e-text-container {
    height: 200px !important;
  }
</style>
