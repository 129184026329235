<template>
    <DatePicker v-model="date" :type="type" :format="format" :editable="false" :placeholder="placeholder"></DatePicker>       
</template>
<script>
export default {
    name: 'customDatePicker',
    props: {
        value: {
            type: Number,
            default: 0
        },
        type: {
            type: String,
            default: 'datetime'
        },
        format: {
            type: String,
            default: 'yyyy-MM-dd HH:mm'
        },
        placeholder: {
            type: String,
            default: '请输入时间'
        }
    },
    data(){
        return {
            date: null
        }
    },
    watch: {
        value: {
            immediate: true,
            handler:function(val){
               this.date = val > 0 ? new Date(val) : null;
            }
        },
        date: {
            immediate: true,
            handler:function(val){
               this.$emit('input',val ? val.getTime() : 0);
            }
        }
    }
}
</script>
