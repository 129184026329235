<template>
  <div class="myarea">
    <Card :padding="10" shadow title="查询条件" class="bt-card">
        <div class="search-con search-con-top">
            <Input v-model.trim="key" class="bt-input" placeholder="登录名 / 昵称 / 姓名" clearable/>
            <Button type="primary" @click="search">搜索</Button>
        </div>
    </Card>

    <Card :padding="10" title="用户列表" shadow>
      <p slot="extra">
          <Button @click="handleAddddUser()" type="primary">新增</Button>
      </p>
      <Table row-key="id" :columns="columns" border :data="list" stripe></Table>
      <Page :show-total="true" :total="total" :current="pn" class-name="bt-page" @on-change="changePage"/>
    </Card>

    <Modal
        v-model="showEditRole"
        :mask-closable="false"
        title="编辑角色"
        :width="400">
      <CheckboxGroup v-model="rs">
        <Checkbox v-for="item in roleList" :key="item.id" :label="item.id" border>{{item.name}}</Checkbox>
      </CheckboxGroup>
      <div slot="footer">
        <Button type="text" @click="showEditRole=false">取消</Button>
        <Button type="primary" @click="save">保存</Button>
      </div>
    </Modal>

    <Modal
        v-model="modal"
        :title="editUser.id == 0 ? '新增用户' : '编辑用户'"
        @on-visible-change="resetForm">
        <Form ref="editUser" :model="editUser" :label-width="100">
            <FormItem label="登录名" prop="username" :rules="{required: true, message: '必填，应为11位手机号码',min: 11,max: 11}">
                <Input v-model.trim="editUser.username" placeholder="请输入登录名"></Input>
            </FormItem>
            <FormItem v-if="editUser.id == 0" label="密码" prop="password" :rules="{required: true, message: '必填，密码长度应在6-16位之间',min: 6,max: 16}">
                <CustomPassword v-model="editUser.password" placeholder="请输入密码"></CustomPassword>
            </FormItem>
            <FormItem v-else label="密码" prop="password" :rules="{required: false}">
                <CustomPassword v-model="editUser.password" placeholder="若密码为空，则默认不修改"></CustomPassword>
            </FormItem>
            <FormItem label="姓名" prop="name" :rules="{required: true, message: '姓名不能为空'}">
                <Input v-model.trim="editUser.name" placeholder="请输入姓名"></Input>
            </FormItem>
            <FormItem label="所属街道" prop="sid" :rules="{required: true,type: 'number',min: 1,message:'请选择街道',trigger: 'blur'}">
                <Select v-model="editUser.sid" placeholder="请选择街道">
                    <Option v-for="(item,index) in streetList" :key="index" :value="item.id" :class="['stree-level-' + item._level]">{{item.name}}</Option>
                </Select>
            </FormItem>
            <FormItem label="用户角色" prop="rs" :rules="{required: true,message:'请选择用户角色'}">
                <CheckboxGroup v-model="editUser.rs">
                    <Checkbox v-for="item in roleList" :key="item.id" :label="item.id" border>{{item.name}}</Checkbox>
                </CheckboxGroup>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="text" size="large" @click="modal=false">取消</Button>
            <Button type="primary" size="large" @click="handleUserSubmit">确定</Button>
        </div>
    </Modal>
  </div>
</template>

<script>
const moment = require('moment');
export default {
  name: 'userMain',
  data() {
    return {
      showEditUser:false,
      user:{},
      currentUser:{},
      rs:[],
      roleList:[],
      streetList:[],
      showEditRole:false,
      key: '',
      list: [],
      pn: 1,
      ps: 20,
      total: 1,
      columns: [{
        key: 'id',
        title: 'ID',
        tree: true,
        align: 'center'
      }, {
        key: 'username',
        title: '登录名',
        align: 'center'
      },{
        key: 'nickname',
        title: '昵称',
        align: 'center',
        width: 150,
        render: (h,{row})=>{
            let data = [];
            if(row.avatar){
                data.push(h('img',{attrs:{src:row.avatar}}));
            }
            if(row.nickname){
                data.push(row.nickname);
            }
            if(data.length > 0){
                let p = h('p',{class:'nickname-ui'},data);
                return h('Tooltip',{props:{placement:'top-start',transfer:true,delay:300}},[p,h('div',{slot:'content'},[p])]);
            }
        }
      },{
        key: 'name',
        title: '姓名',
        align: 'center'
      },{
        key: 'utype',
        title: '用户类型',
        align: 'center',
        render: (h,{row})=>{
            if(row.utype == 1){
                return h('Tag',{props: {color: 'warning'}},'企业用户');
            }else if(row.utype == 2){
                return h('Tag',{props: {color: 'primary'}},'管理用户');
            }
        }
      },{
        key: 'sname',
        title: '所属街道',
        align: 'center'
      },{
        key: 'createTime',
        title: '创建时间',
        align: 'center',
        render: (h, params) => {
          return h('div',moment(params.row.createTime).format('YYYY-MM-DD HH:mm:ss'));
        }
      },{
        key: 'option',
        title: '操作',
        render: (h, {row,index}) => {
          let data = [];
          data.push(h('Button', {
            props: {type: 'success', size: 'small'},
            style: {marginRight: '5px'},
            on: {
              click: () => {
                this.handleEditUser(index);
              }
            }
          }, "编辑"));
          data.push(h('Button', {
            props: {type: 'info', size: 'small'},
            style: {marginRight: '5px'},
            on: {
              click: () => {
                this.editRole(row);
              }
            }
          }, "编辑角色"));
          return h('div', data);
        }
      }],
      modal: false,
      editUser: {
        id: 0,
        username: '',
        password: '',
        name: '',
        sid: 0,
        rs: []
      }
    }
  },
  created() {
    this.queryList();
    this.getRoleList();
    this.getStreetList();
  },
  methods: {
    handleAddddUser(){
        this.modal = true;
    },
    handleEditUser(index){
        let user = Object.assign({},this.list[index]);
        user.password = '';
        user.rs = [];
        this.getRoleIdListByUid(user.id,(data)=>{
            user.rs = data || [];
        });
        this.editUser = user;
        this.modal = true;
    },
    handleUserSubmit(){
        this.$refs['editUser'].validate((valid) => {
            if(valid) {
                let user = Object.assign({},this.editUser);
                this.$http.post('/api/user/saveUser',user).then(()=>{
                    this.$Message.success("操作成功");
                    this.queryList();
                    this.modal = false;
                });
            }
        });
    },
    resetForm(show){
        if(!show) {
            this.$refs.editUser.resetFields();
            this.editUser.id = 0;
        }
    },
    editRole(user){
        this.currentUser=user;
        this.getRoleIdListByUid(user.id);
        this.showEditRole=true;
    },
    save(){
        let self=this;
        this.$http.post('/api/other/saveUserRole',{uid:this.currentUser.id,rids:this.rs}).then(()=>{
            self.showEditRole=false;
            self.$Message.success({content: '保存成功！'});
        });
    },
    search(){
        this.pn=1;
        this.queryList();
    },
    queryList() {
        this.$http.get('/api/user/getUserList', {
            key: this.key,
            pn: this.pn,
            ps: this.ps
        }).then(data => {
            this.list = data.list;
            this.total = data.total;
        });
    },
    changePage(pn) {
        this.pn = pn;
        this.queryList();
    },
    getRoleList() {
        this.$http.get('/api/other/getRoleList').then(data => {
            this.roleList = data;
        });
    },
    getRoleIdListByUid(uid,callback=()=>{}) {
        this.$http.get('/api/other/getRoleIdListByUid',{uid:uid}).then(data => {
            this.rs = data;
            callback(data);
        });
    },
    getStreetList(){
        this.$http.get('/api/other/getAllStreetList').then(data=>{
            let streetList = [];
            let parentMap = {};
            (data || []).forEach(item=>{
                let pid = item.pid || item.id;
                let parent = parentMap[pid] || {childList: []};
                parentMap[pid] = parent;
                if(pid == item.id){
                    item._level = 1;
                    parent.item = item;
                }else {
                    item._level = 2;
                    parent.childList.push(item);
                }
            });
            for(let key in parentMap){
                let parent = parentMap[key];
                if(parent.item){
                    streetList.push(parent.item);
                }
                streetList = streetList.concat(parent.childList || []);
            }
            this.streetList = streetList;
        });
    }
  }
}
</script>
<style scoped>
.stree-level-2{
    padding-left: 27px;
}
</style>