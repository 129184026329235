<template>
    <div class="si-carrier-manager">
         <Card :padding="10" shadow title="查询条件" class="bt-card">
            <div class="search-con search-con-top">
                <Input v-model.trim="key" clearable @on-enter="searchSiCarrierList" @on-clear="searchSiCarrierList" placeholder="名称" class="search-input"/>
                <Button type="primary" icon="md-search" @click="searchSiCarrierList">搜索</Button>
            </div>
        </Card>
        
        <Card :padding="10" title="科创载体列表" shadow>
            <p slot="extra">
               <Button @click="handleAddSiCarrier" type="primary">新增</Button>
            </p>
            <Table :columns="columns" border :data="list" stripe></Table>
            <Page :total="total" :current="page" :page-size="size" @on-change="handlePageChange" @on-page-size-change='handlePageSize' show-total show-elevator show-sizer class-name="bt-page"/>
        </Card>

        <Modal
            v-model="modal"
            :title="editSiCarrier.id == 0 ? '新增科创载体' : '编辑科创载体'"
            @on-visible-change="resetForm"
            :width="600">
            <Form ref="editSiCarrier" :model="editSiCarrier" :label-width="100">
                <FormItem label="名称" prop="name" :rules="{required: true, message: '名称不能为空'}">
                   <Input v-model.trim="editSiCarrier.name" placeholder="请输入名称"></Input>
                </FormItem>
                <FormItem label="图片" prop="pic" :rules="{required: true, message: '图片不能为空'}">
                    <CustomUpload v-model="editSiCarrier.pic"></CustomUpload>
                </FormItem>
                <FormItem label="介绍" prop="introduce" :rules="{required: true, message: '介绍不能为空'}">
                    <editor v-model="editSiCarrier.introduce"></editor>
                </FormItem>
                <FormItem label="排序码" prop="orders" :rules="{required: true, min: 0,type: 'number',message: '排序码不能为空', trigger: 'change'}">
                   <InputNumber v-model="editSiCarrier.orders" placeholder="请输入排序码"></InputNumber>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button type="text" size="large" @click="modal=false">取消</Button>
                <Button type="primary" size="large" @click="handleSiCarrierSubmit">确定</Button>
            </div>
        </Modal>

        <Modal
            v-model="auditBackModal"
            title="审核打回">
            <Form ref="auditBackValue" :model="auditBackValue" :label-width="100">
                <FormItem label="原因" prop="reason" :rules="{required: true, message: '原因不能为空'}">
                   <Input v-model.trim="auditBackValue.reason" maxlength="500" show-word-limit type="textarea" placeholder="请输入原因"></Input>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button type="text" size="large" @click="auditBackModal=false">取消</Button>
                <Button type="primary" size="large" @click="handleAuditBackSubmit">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import {renderShowImage,formatDate} from '@/utils';
import editor from '@/components/common/Editor';

export default {
    name: 'siCarrierManager',
    data(){
        return {
            key: '',
            list: [],
            page: 1,
            size: 20,
            total: 1,
            columns:[{
              key: 'id',
              title: 'ID',
              align: 'center'
           },{
              key: 'name',
              title: '名称',
              tooltip: true
           },{
              key: 'pic',
              title: '图片',
              align: 'center',
              render: renderShowImage
           },{
              key: 'orders',
              title: '排序码',
              align: 'center'
           },{
              key: 'status',
              title: '审核状态',
              align: 'center',
              render: (h,{row,index})=>{
                if(row.status == 0){
                    return h('Tag',{props: {color: 'default'}},'待审核');
                }else if(row.status == 1){
                    return h('Tag',{props: {color: 'primary'}},'审核通过');
                }else {
                    return h('Tag',{props: {color: 'error'},
                        nativeOn: {
                            click: () => {
                                this.lookAuditBackReason(index);
                            }
                        }
                    },'审核失败');
                }
             }
           },{
              key: 'createTime',
              title: '创建时间',
              align: 'center',
              render: (h,{row})=>{
                  return h('span',formatDate(row.createTime,'yyyy-MM-dd hh:mm:ss'));
              }
           },{
              key: 'modifyTime',
              title: '修改时间',
              align: 'center',
              render: (h,{row})=>{
                  return h('span',formatDate(row.modifyTime,'yyyy-MM-dd hh:mm:ss'));
              }
           },{
              key: 'option',
              title: '操作',
              minWidth: 120,
              render: (h, {row,index}) => {
                 let data = [];
                 if(row.status != 1){
                     data.push(h('Button',{
                        props: {type: 'info',size: 'small'},
                        style: {marginRight: '5px'},
                        on: {
                            click: () => {
                                this.handleEditSiCarrierStatus(index,1);
                            }
                        },
                        directives:[{
                            name: 'permission',
                            value: 'siCarrier:audit'
                        }]
                    }, "审核通过"));
                 }
                 if(row.status != 2){
                    data.push(h('Button',{
                        props: {type: 'error',size: 'small'},
                        style: {marginRight: '5px'},
                        on: {
                            click: () => {
                                this.handleEditSiCarrierStatus(index,2);
                            }
                        },
                        directives:[{
                            name: 'permission',
                            value: 'siCarrier:audit'
                        }]
                    }, "审核打回"));
                 }
                 data.push(h('Button',{
                        props: {type: 'success',size: 'small'},
                        style: {marginRight: '5px'},
                        on: {
                            click: () => {
                                this.handleEditSiCarrier(index);
                            }
                        }
                    }, "编辑"));
                 data.push(h('Button',{
                        props: {type: 'error',size: 'small'},
                        style: {marginRight: '5px'},
                        on: {
                            click: () => {
                                this.handleDeleteSiCarrier(index);
                            }
                        }
                    }, "删除"));
                 return h('div', data);
              }
            }],
            modal: false,
            editSiCarrier: {
               id: 0,
               name: '',
               thumbnail: '',
               pic: '',
               introduce: '',
               orders: 0,
               status: 0
            },
            auditBackModal: false,
            auditBackValue: {
                index: 0,
                status: 0,
                reason: ''
            }
        }
    },
    created(){
        this.searchSiCarrierList();
    },
    methods: {
        searchSiCarrierList(){
            this.page = 1;
            this.querySiCarrierList();
        },
        querySiCarrierList(){
            this.$http.post('/api/admin/getSiCarrierList',{
                key: this.key,
                pn: this.page,
                ps: this.size
            }).then(data=>{
                this.list = data.list;
                this.size = data.pageSize;
                this.total = data.total;
            });
        },
        handleAddSiCarrier(){
            this.editSiCarrier.id = 0;
            this.modal = true;
        },
        handleEditSiCarrier(index){
            this.editSiCarrier = Object.assign({},this.list[index]);
            this.modal = true;
        },
        handleSiCarrierSubmit(){
            this.$refs['editSiCarrier'].validate((valid) => {
              if(valid) {
                 let siCarrier = Object.assign({},this.editSiCarrier);
                 this.$http.post('/api/admin/saveSiCarrier',siCarrier).then(()=>{
                    this.$Message.success("操作成功");
                    this.querySiCarrierList();
                    this.modal = false;
                 });
              }
           });
        },
        openAuditBack(id,status){
           this.auditBackValue = {
               id: id,
               status: status,
               reason: ''
           };
           this.auditBackModal = true;
           this.$refs['auditBackValue'].resetFields();
        },
        handleAuditBackSubmit(){
            this.$refs['auditBackValue'].validate((valid) => {
              if(valid) {
                  this.$http.post('api/admin/updateSiCarrierStatus',this.auditBackValue).then(()=>{
                      this.$Message.success("审核打回成功");
                      this.auditBackModal = false;
                      this.querySiCarrierList();
                  });
              }
           });
        },
        handleEditSiCarrierStatus(index,status){
            let siCarrier = this.list[index];
            if(status == 1){
                this.$Modal.confirm({
                    title: '审核操作',
                    content: '确认审核通过吗？',
                    onOk: () => {
                        this.$http.post('api/admin/updateSiCarrierStatus',{id: siCarrier.id,status: 1}).then(()=>{
                            this.$Message.success("审核通过成功");
                            this.querySiCarrierList();
                        });
                    }
                });
            }else if(status == 2){
                this.openAuditBack(siCarrier.id,status);
            }else {
                this.$Message.error("无效状态");
            }
        },
        handleDeleteSiCarrier(index){
            this.$Modal.confirm({
              title: '删除操作',
              content: '确认删除该科创载体吗？',
              onOk: () => {
                 let siCarrier = this.list[index];
                 this.$http.post('api/admin/deleteSiCarrier',{id: siCarrier.id}).then(()=>{
                    this.$Message.success("删除成功");
                    this.querySiCarrierList();
                 });
              }
           });
        },
        lookAuditBackReason(index){
            this.$http.post('api/admin/queryAuditLogReason',{itemId: this.list[index].id,itemType: 5},false).then((data)=>{
                this.$Modal.error({
                    title: '打回原因',
                    content: data
                });
            });
        },
        resetForm(show){
           if(!show) {
              this.$refs.editSiCarrier.resetFields();
              this.editSiCarrier.id = 0;
           }
        },
        handlePageChange(page){
           this.page = page;
           this.querySiCarrierList();
        },
        handlePageSize(size){
           this.size = size;
           this.page = 1;
           this.querySiCarrierList();
        }
    },
    components: {editor}
}
</script>