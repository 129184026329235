<template>
  <div class="enterprise-main">
    <Card :padding="10" shadow title="查询条件" class="bt-card">
        <div class="search-con search-con-top">
            <Input v-model.trim="name" class="bt-input" placeholder="名称" clearable/>
            <Button type="primary" @click="search">搜索</Button>&nbsp;&nbsp;
            <Button type="primary" @click="addEnterprise">新增</Button>
        </div>
    </Card>

    <Card :padding="10" title="企业列表" shadow>
      <Table :columns="columns" border :data="list" stripe></Table>
      <Page :show-total="true" :total="total" :current="pn" class-name="bt-page" @on-change="changePage"/>
    </Card>

    <Modal
        v-model="modal"
        :title="!editEnterprise.id ? '新增企业' : '编辑企业'"
        :width="600"
        @on-visible-change="resetForm">
        <Form ref="editEnterprise" :model="editEnterprise" :label-width="120">
            <FormItem label="企业名称" prop="name" :rules="{required: true,message:'必填，且长度不能超过20个字',max:20}">
                <Input v-model="editEnterprise.name" placeholder="企业名称"></Input>
            </FormItem>
            <FormItem label="信用代码" prop="code" :rules="{required: true,message:'必填，且长度不能超过20个字',max:20}">
                <Input v-model="editEnterprise.code" placeholder="统一社会信用代码"></Input>
            </FormItem>
            <FormItem label="注册地址" prop="registerAddress" :rules="{required: true,message:'必填，且长度不能超过50个字',max:50}">
                <Input v-model="editEnterprise.registerAddress" placeholder="企业注册地址"></Input>
            </FormItem>
            <FormItem label="办公地址" prop="address" :rules="{required: false,message:'长度不能超过50个字',max:50}">
                <Input v-model="editEnterprise.address" placeholder="企业实际办公地址"></Input>
            </FormItem>
            <FormItem label="所属街道(园区)" prop="sid" :rules="{required: true,message:'必填'}">
                <Select v-model="editEnterprise.sid" placeholder="请选择街道">
                    <Option v-for="item in streetList" :key="item.id" :value="item.id">{{item.name}}</Option>
                </Select>
            </FormItem>
            <FormItem label="行业领域" prop="industry" :rules="{required: false,message:'长度不能超过20个字',max:20}">
                <AutoComplete
                    v-model="editEnterprise.industry"
                    :data="industryList"
                    placeholder="行业领域" />
            </FormItem>
            <FormItem label="企业资质" prop="qualification" :rules="{required: false,message:'长度不能超过20个字',max:20}">
                <AutoComplete
                    v-model="editEnterprise.qualification"
                    :data="qList"
                    placeholder="企业资质" />
            </FormItem>
            <FormItem label="承担项目" prop="undertakeProject" :rules="{required: false,message:'长度不能超过20个字',max:20}">
                <Input v-model="editEnterprise.undertakeProject" placeholder="XXX省级项目、XXX市级项目"></Input>
            </FormItem>
            <FormItem label="企业类型" prop="etype" :rules="{required: true,message:'必填'}">
                <Select v-model="editEnterprise.etype" placeholder="企业类型">
                    <Option v-for="item in typeList" :key="item" :value="item">{{item}}</Option>
                </Select>
            </FormItem>
            <FormItem label="营业收入" prop="income2021" :rules="{required: false}">
                <Select v-model="editEnterprise.income2021" placeholder="营业收入">
                    <Option v-for="item in feeList" :key="item" :value="item">{{item}}</Option>
                </Select>
            </FormItem>
            <FormItem label="研发投入" prop="putin2021" :rules="{required: false,message:'必填'}">
                <Select v-model="editEnterprise.putin2021" placeholder="研发投入">
                    <Option v-for="item in feeList" :key="item" :value="item">{{item}}</Option>
                </Select>
            </FormItem>
            <FormItem label="核心产品简介" prop="productIntroduce" :rules="{required: false,message:'必填'}">
                <editor v-model="editEnterprise.productIntroduce"></editor>
            </FormItem>
            <FormItem label="企业技术需求" prop="requirements" :rules="{required: false,message:'必填且长度不能超过500个字',max:500}">
                <Input type="textarea" v-model="editEnterprise.requirements" placeholder="企业技术需求"></Input>
            </FormItem>
            <FormItem label="联系人名字" prop="contactName" :rules="{required: false,message:'必填，且长度不能超过10个字',max:10}">
                <Input v-model="editEnterprise.contactName" placeholder="联系人名字"></Input>
            </FormItem>
            <FormItem label="联系人职位" prop="contactJob" :rules="{required: false,message:'必填，且长度不能超过10个字',max:10}">
                <Input v-model="editEnterprise.contactJob" placeholder="联系人职位"></Input>
            </FormItem>
            <FormItem label="联系人手机号" prop="contactPhone" :rules="{required: false,message:'必填，且长度不能超过11个字',max:11}">
                <Input v-model="editEnterprise.contactPhone" placeholder="联系人手机号"></Input>
            </FormItem>
            <FormItem label="缩略图" prop="thumbnail">
                <CustomUpload v-model="editEnterprise.thumbnail"></CustomUpload>
            </FormItem>
            <FormItem label="图片" prop="mainPic" :rules="{required: false, message: '图片不能为空'}">
                <CustomUpload v-model="editEnterprise.mainPic"></CustomUpload>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="text" size="large" @click="modal=false">取消</Button>
            <Button type="primary" size="large" @click="handleEnterpriseSubmit">确定</Button>
        </div>
    </Modal>
    
    <Modal
        v-model="auditBackModal"
        title="审核打回">
        <Form ref="auditBackValue" :model="auditBackValue" :label-width="100">
            <FormItem label="原因" prop="reason" :rules="{required: true, message: '原因不能为空'}">
                <Input v-model.trim="auditBackValue.reason" placeholder="请输入原因"></Input>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="text" size="large" @click="auditBackModal=false">取消</Button>
            <Button type="primary" size="large" @click="handleAuditBackSubmit">确定</Button>
        </div>
    </Modal>
  </div>
</template>

<script>
import editor from '@/components/common/Editor';
const moment = require('moment');

export default {
  name: 'enterpriseMain',
  data() {
    return {
      currentUser:{},
      rs:[],
      roleList:[],
      showEditRole:false,
      name: '',
      list: [],
      pn: 1,
      ps: 20,
      total: 1,
      columns: [ {
        key: 'name',
        title: '名称',
        align: 'center'
      }, {
        key: 'code',
        title: '信用代码',
        align: 'center'
      }, {
        key: 'sname',
        title: '所属街道',
        align: 'center'
      }, {
        key: 'industry',
        title: '行业领域',
        align: 'center'
      }, {
        key: 'qualification',
        title: '企业资质',
        align: 'center'
      }, {
        key: 'undertakeProject',
        title: '承担项目',
        align: 'center'
      }, {
        key: 'income2021',
        title: '营业收入',
        align: 'center'
      },{
        key: 'putin2021',
        title: '研发投入',
        align: 'center'
      },{
        key: 'contactName',
        title: '联系人名字',
        align: 'center'
      },{
        key: 'contactJob',
        title: '联系人职位',
        align: 'center'
      },{
        key: 'contactPhone',
        title: '联系人手机号',
        align: 'center'
      }, {
        key: 'status',
        title: '审核状态',
        align: 'center',
        render: (h,{row,index})=>{
            if(row.status == 0){
                return h('Tag',{props: {color: 'default'}},'待审核');
            }else if(row.status == 1){
                return h('Tag',{props: {color: 'primary'}},'审核通过');
            }else {
                return h('Tag',{props: {color: 'error'},
                    nativeOn: {
                        click: () => {
                            this.lookAuditBackReason(index);
                        }
                    }
                },'审核失败');
            }
        }
    },{
        key: 'createTime',
        title: '创建时间',
        align: 'center',
        render: (h, params) => {
          return h('div',moment(params.row.createTime).format('YYYY-MM-DD HH:mm:ss'));
        }
      },{
        key: 'option',
        title: '操作',
        render: (h, {row,index}) => {
          let data = [];
          if(row.status != 1){
                data.push(h('Button',{
                    props: {type: 'info',size: 'small'},
                    style: {marginRight: '5px'},
                    on: {
                        click: () => {
                            this.handleEditEnterpriseStatus(index,1);
                        }
                    },
                    directives:[{
                        name: 'permission',
                        value: 'enterprise:audit'
                    }]
                }, "审核通过"));
            }
            if(row.status != 2){
                data.push(h('Button',{
                    props: {type: 'error',size: 'small'},
                    style: {marginRight: '5px'},
                    on: {
                        click: () => {
                            this.handleEditEnterpriseStatus(index,2);
                        }
                    },
                    directives:[{
                        name: 'permission',
                        value: 'enterprise:audit'
                    }]
                }, "审核打回"));
          }
          data.push(h('Button', {
            props: {type: 'success', size: 'small'},
            style: {marginRight: '5px'},
            on: {
              click: () => {
                this.handleEditEnterprise(index);
              }
            }
          }, "编辑"));
          return h('div', data);
        }
      }],
      auditBackModal: false,
      auditBackValue: {
         id: 0,
         status: 0,
         reason: ''
      },
      modal: false,
      editEnterprise: {},
      streetList: [],
      industryList:["电子信息","生物与新医药","航空航天","新材料","高技术服务","新能源与节能","资源与环境","先进制造与自动化","其他"],
      qList:["高新技术企业","江苏省工程技术研究中心"],
      feeList:["100万元以下","100万元-500万元","500万元-1000万元","1000万元-1500万元","1500万元-2000万元","2000万元以上"],
      typeList:['科技型中小企业','瞪羚技术企业','高新技术企业','培育独角兽技术企业','独角兽技术企业'],
    }
  },
  created() {
    this.queryList();
    this.getStreetList();
  },
  methods: {
    handleEnterpriseSubmit(){
        this.$refs['editEnterprise'].validate((valid) => {
            if(valid) {
                this.$http.post('/api/enterprise/save',this.editEnterprise).then(data=>{
                    this.$Message.success('操作成功');
                    this.queryList();
                    this.modal = false;
                });
            }
        });
    },
    handleEditEnterprise(index){
        this.editEnterprise = this.filterNullValue(Object.assign({},this.list[index]));
        this.modal = true;
    },
    addEnterprise(){
        this.editEnterprise={};
        this.modal=true;
    },
    filterNullValue(obj){
        for(let key in obj){
            if(key == 'sid'){
                if(obj[key] == null){
                    obj[key] = 0;
                }
                continue;
            }
            if(obj[key] == null){
                obj[key] = '';
            }
        }
        return obj;
    },
    resetForm(show){
        if(!show) {
            this.$refs.editEnterprise.resetFields();
            this.editEnterprise.id = 0;
        }
    },
    edit(){
      this.$router.push({path:'/enterprise/addEnterprise'})
    },
    search(){
      this.pn=1;
      this.queryList();
    },
    queryList() {
      this.$http.get('/api/enterprise/getEnterpriseList', {
        key: this.name,
        pn: this.pn,
        ps: this.ps
      }).then(data => {
        this.list = data.list;
        this.total = data.total;
      });
    },
    changePage(pn) {
      this.pn = pn;
      this.queryList();
    },
    openAuditBack(id,status){
        this.auditBackValue = {
            id: id,
            status: status,
            reason: ''
        };
        this.auditBackModal = true;
        this.$refs['auditBackValue'].resetFields();
    },
    handleAuditBackSubmit(){
        this.$refs['auditBackValue'].validate((valid) => {
            if(valid) {
                this.$http.post('api/enterprise/updateEnterpriseStatus',this.auditBackValue).then(()=>{
                    this.$Message.success("审核打回成功");
                    this.auditBackModal = false;
                    this.queryList();
                });
            }
        });
    },
    handleEditEnterpriseStatus(index,status){
        let enterprise = this.list[index];
        if(status == 1){
            this.$Modal.confirm({
                title: '审核操作',
                content: '确认审核成功吗？',
                onOk: () => {
                    this.$http.post('api/enterprise/updateEnterpriseStatus',{id: enterprise.id,status: status}).then(()=>{
                        this.$Message.success("审核成功成功");
                        this.queryList();
                    });
                }
            });
        }else if(status == 2){
            this.openAuditBack(enterprise.id,status);
        }else {
            this.$Message.error("无效状态");
        }
    },
    getStreetList(){
        this.$http.get('/api/other/getStreetList').then(data=>{
            this.streetList = data;
        });
    },
    lookAuditBackReason(index){
        this.$http.post('api/admin/queryAuditLogReason',{itemId: this.list[index].id,itemType: 2},false).then((data)=>{
            this.$Modal.error({
                title: '打回原因',
                content: data
            });
        });
    }
  },
  components: {editor}
}
</script>