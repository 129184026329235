<template>
  <div class="notice-manager">
    <Card :padding="10" shadow title="查询条件" class="bt-card">
      <div class="search-con search-con-top">
        <Input v-model.trim="key" clearable @on-enter="searchNoticeList" @on-clear="searchNoticeList" placeholder="标题"
               class="search-input"/>
        <Button type="primary" icon="md-search" @click="searchNoticeList">搜索</Button>
      </div>
    </Card>

    <Card :padding="10" title="角色列表" shadow>
      <Table :check-strictly="true" :columns="columns" border :data="list" stripe></Table>
    </Card>

    <Modal
        v-model="showEditRoleMenu"
        :mask-closable="false"
        title="编辑角色权限"
        :width="400">
      <Tree ref="mytree" :check-strictly="true" @on-check-change="changeCheck" :check-directly="true" :data="menuList" :multiple="true" :show-checkbox="true"></Tree>
      <div slot="footer">
        <Button type="text" @click="showEditRoleMenu=false">取消</Button>
        <Button type="primary" @click="save">保存</Button>
      </div>
    </Modal>


  </div>
</template>

<script>
import {renderShowImage, formatDate} from '@/utils';
import editor from '@/components/common/Editor';
import {baseUrl} from '@/api/fetch';
import {getToken} from "@/utils";

export default {
  name: 'roleMain',
  data() {
    return {
      treeList:[],
      showEditRoleMenu:false,
      menuList:[],
      currentRole: {},
      key: '',
      list: [],
      columns: [{
        key: 'id',
        title: 'ID',
        align: 'center'
      }, {
        key: 'code',
        title: '代码',
        tooltip: true
      }, {
        key: 'name',
        title: '名称',
        align: 'center'
      }, {
        key: 'option',
        title: '操作',
        render: (h, {row}) => {
          let data = [];
          data.push(h('Button', {
            props: {type: 'success', size: 'small'},
            style: {marginRight: '5px'},
            on: {
              click: () => {
                this.editRoleMenu(row);
              }
            }
          }, "编辑权限"));
          return h('div', data);
        }
      }],
      modal: false,
      editNotice: {
        id: 0,
        type: 0,
        image: '',
        title: '',
        content: ''
      },
      uploadPath: baseUrl + '/api/admin/uploadFile',
      header: {
        'Authorization': 'Bearer ' + getToken()
      }
    }
  },
  created() {
    this.queryNoticeList();
  },
  methods: {
    changeCheck(list,nt){
      this.changeTree(nt);
      this.changeParent(this.treeList,nt);
    },
    searchNoticeList() {
      this.pn = 1;
      this.queryNoticeList();
    },
    queryNoticeList() {
      this.$http.get('/api/other/getRoleList', {
        key: this.key
      }).then(data => {
        this.list = data;
      });
    },
    editRoleMenu(role) {
      this.currentRole=role;
      this.queryMenuList();
      this.showEditRoleMenu = true;
    },
    save(){
      let notes=this.$refs.mytree.getCheckedAndIndeterminateNodes().map(item=>item.id);
      let self=this;
      this.$http.post('/api/other/saveRoleMenu',{rid:this.currentRole.id,mids:notes}).then(()=>{
        self.showEditRoleMenu=false;
        self.$Message.success({content: '保存成功！'});
      });
    },
    changeParent(list,nt){
      if(nt.checked && !!nt.pid){
        let array=list.filter(item=>item.id==nt.pid);
        console.log(list,array);
        if(array.length>0){
          array[0].checked=true;
          this.changeParent(list,array[0]);
        }
      }
    },
    changeTree(nt){
      nt.children.forEach(item=>{
        item.checked = nt.checked;
        if (item.children.length > 0) {
          this.changeTree(item);
        }
      })
    },
    getMenuList(list){
      list.forEach(item=>{
        this.treeList.push(item);
        if(item.children.length>0){
          this.getMenuList(item.children);
        }
      })
    },
    queryMenuList(){
      this.$http.get('/api/other/getSysMenus',{
        rid: this.currentRole.id
      }).then(data=>{
        this.menuList = data;
        this.getMenuList(data);
      });
    }
  },
  components: {}
}
</script>