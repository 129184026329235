<template>
    <div class="banner-manager">
         <Card :padding="10" shadow title="查询条件" class="bt-card">
            <div class="search-con search-con-top">
                <Input v-model.trim="key" clearable @on-enter="searchBannerList" @on-clear="searchBannerList" placeholder="标题" class="search-input"/>
                <Button type="primary" icon="md-search" @click="searchBannerList">搜索</Button>
            </div>
        </Card>
        
        <Card :padding="10" title="轮播图列表" shadow>
            <p slot="extra">
               <Button @click="handleAddBanner" type="primary">新增</Button>
            </p>
            <Table :columns="columns" border :data="list" stripe></Table>
            <Page :total="total" :current="page" :page-size="size" @on-change="handlePageChange" @on-page-size-change='handlePageSize' show-total show-elevator show-sizer class-name="bt-page"/>
        </Card>

        <Modal
            v-model="modal"
            :title="editBanner.id == 0 ? '新增轮播图' : '编辑轮播图'"
            @on-visible-change="resetForm">
            <Form ref="editBanner" :model="editBanner" :label-width="120">
                <FormItem label="标题" prop="name">
                   <Input v-model.trim="editBanner.name" placeholder="请输入标题"></Input>
                </FormItem>
                <FormItem label="图片" prop="path" :rules="{required: true, message: '图片不能为空'}">
                    <CustomUpload v-model="editBanner.path"></CustomUpload>
                </FormItem>
                <FormItem label="板块类型" prop="itemType">
                    <Select v-model="editBanner.itemType" @on-change="changeItem" clearable>
                        <Option v-for="item in itemList" :value="item.code" :key="item.code">{{item.name}}</Option>
                    </Select>
                </FormItem>
                <FormItem v-if="validItem" :label="'选择' + itemName" prop="itemId" :rules="{required: true, message: '请选择' + itemName}">
                    <v-selectpage :title="'选择' + itemName" v-model="editBanner.itemId" ref="itemSelect" :data="itemUrl" search-field="key" :tb-columns="itemColumns" :key-field="keyField" :show-field="showField" class="form-control" placeholder="请输入关键词" :result-format="resultFormat"></v-selectpage>
                </FormItem>
                <FormItem label="启用" prop="status">
                    <i-switch v-model="editBanner.status" :true-value="1" :false-value="0"></i-switch>
                </FormItem>
                <FormItem label="排序码" prop="orders" :rules="{required: true, min: 0,type: 'number',message: '排序码不能为空', trigger: 'change'}">
                   <InputNumber v-model="editBanner.orders" placeholder="请输入排序码"></InputNumber>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button type="text" size="large" @click="modal=false">取消</Button>
                <Button type="primary" size="large" @click="handleBannerSubmit">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import {renderShowImage,formatDate,resetSelectPage} from '@/utils';

export default {
    name: 'bannerManager',
    data(){
        return {
            key: '',
            list: [],
            page: 1,
            size: 20,
            total: 1,
            columns:[{
              key: 'id',
              title: 'ID',
              align: 'center'
           },{
              key: 'name',
              title: '标题',
              tooltip: true
           },{
              key: 'path',
              title: '图片',
              align: 'center',
              render: renderShowImage
           },{
              key: 'orders',
              title: '排序码',
              align: 'center'
           },{
              key: 'status',
              title: '状态',
              align: 'center',
              render: (h,{row})=>{
                 if(row.status == 1){
                     return h('Tag',{props: {color: 'primary'}},'已启用');
                 }else {
                     return h('Tag',{props: {color: 'default'}},'未启用');
                 }
              }
           },{
              key: 'createTime',
              title: '创建时间',
              align: 'center',
              render: (h,{row})=>{
                  return h('span',formatDate(row.createTime,'yyyy-MM-dd hh:mm:ss'));
              }
           },{
              key: 'modifyTime',
              title: '修改时间',
              align: 'center',
              render: (h,{row})=>{
                  return h('span',formatDate(row.modifyTime,'yyyy-MM-dd hh:mm:ss'));
              }
           },{
              key: 'option',
              title: '操作',
              minWidth: 120,
              render: (h, {index}) => {
                 let data = [];
                 data.push(h('Button',{
                        props: {type: 'success',size: 'small'},
                        style: {marginRight: '5px'},
                        on: {
                            click: () => {
                                this.handleEditBanner(index);
                            }
                        }
                    }, "编辑"));
                 data.push(h('Button',{
                        props: {type: 'error',size: 'small'},
                        style: {marginRight: '5px'},
                        on: {
                            click: () => {
                                this.handleDeleteBanner(index);
                            }
                        }
                    }, "删除"));
                 return h('div', data);
              }
            }],
            modal: false,
            editBanner: {
               id: 0,
               name: '',
               path: '',
               status: 0,
               orders: 0,
               itemType: 0,
               itemId: '',
               miniPath: ''
            },
            itemList: [{
                code: 2,
                name: '科技企业'
            },{
                code: 3,
                name: '科技人才'
            },{
                code: 4,
                name: '科研平台'
            },{
                code: 5,
                name: '科创载体'
            },{
                code: 6,
                name: '科技咨询'
            },{
                code: 7,
                name: '成果交互'
            },{
                code: 8,
                name: '栖智风采'
            },{
                code: 9,
                name: '科普天地'
            }],
            itemName: '',
            itemUrl: '',
            itemColumns: [],
            keyField: 'id',
            showField: '',
            currentItemId: '',
            validItem: false,
            miniPath: ''
        }
    },
    created(){
        this.searchBannerList();
    },
    activated(){
        this.queryBannerList();
    },
    methods: {
        changeItem(event){
            if(event){
                let miniPath = '';
                if(event == 2){
                    this.itemName = '科技企业';
                    this.itemUrl = '/api/enterprise/getEnterpriseList?status=1';
                    this.itemColumns = [
                        {title: 'ID',data: 'id'},
                        {title: '姓名',data: 'name'}
                    ];
                    this.showField = 'name';
                    miniPath = '/pages/tech-enterprise/detail?id=';
                }else if(event == 3){
                    this.itemName = '科技人才';
                    this.itemUrl = '/api/admin/getTechTalentList?status=1';
                    this.itemColumns = [
                        {title: 'ID',data: 'id'},
                        {title: '姓名',data: 'name'}
                    ];
                    this.showField = 'name';
                    miniPath = '/pages/tech-talent/detail?id=';
                }else if(event == 4){
                    this.itemName = '科研平台';
                    this.itemUrl = '/api/admin/getSrPlatformList?status=1';
                    this.itemColumns = [
                        {title: 'ID',data: 'id'},
                        {title: '名称',data: 'name'}
                    ];
                    this.showField = 'name';
                    miniPath = '/pages/sr-platform/detail?id=';
                }else if(event == 5){
                    this.itemName = '科创载体';
                    this.itemUrl = '/api/admin/getSiCarrierList?status=1';
                    this.itemColumns = [
                        {title: 'ID',data: 'id'},
                        {title: '名称',data: 'name'}
                    ];
                    this.showField = 'name';
                    miniPath = '/pages/si-carrier/detail?id=';
                }else if(event == 6){
                    this.itemName = '科技咨询';
                    this.itemUrl = '/api/admin/getTechConsultList?status=1';
                    this.itemColumns = [
                        {title: 'ID',data: 'id'},
                        {title: '标题',data: 'title'}
                    ];
                    this.showField = 'title';
                    miniPath = '/pages/tech-consult/detail?id=';
                }else if(event == 7){
                    this.itemName = '成果交互';
                    this.itemUrl = '/api/admin/getResultInteractionList?status=1';
                    this.itemColumns = [
                        {title: 'ID',data: 'id'},
                        {title: '标题',data: 'title'}
                    ];
                    this.showField = 'title';
                    miniPath = '/pages/result-interaction/detail?id=';
                }else if(event == 8){
                    this.itemName = '栖智风采';
                    this.itemUrl = '/api/admin/getMienList?status=1';
                    this.itemColumns = [
                        {title: 'ID',data: 'id'},
                        {title: '标题',data: 'title'}
                    ];
                    this.showField = 'title';
                    miniPath = '/pages/mien/detail?id=';
                }else if(event == 9){
                    this.itemName = '科普天地';
                    this.itemUrl = '/api/admin/getPopularWorldList?status=1';
                    this.itemColumns = [
                        {title: 'ID',data: 'id'},
                        {title: '标题',data: 'title'}
                    ];
                    this.showField = 'title';
                    miniPath = '/pages/popular-world/detail?id=';
                }
                this.currentItemId = event;
                this.miniPath = miniPath;
                this.validItem = true;
            }else {
                this.editBanner.itemType = 0;
                this.editBanner.itemId = '';
                this.miniPath = '';
                this.validItem = false;
            }
            this.$nextTick(()=>{
                let fields = this.$refs['editBanner'].fields;
                if(fields){
                    for(let i=0;i<fields.length;i++){
                        let field = fields[i];
                        if(field.validateMessage && field.prop == 'itemId'){
                            this.$refs.editBanner.validateField('itemId');
                            break;
                        }
                    }
                }
            });
        },
        searchBannerList(){
            this.page = 1;
            this.queryBannerList();
        },
        queryBannerList(){
            this.$http.post('/api/admin/getBannerList',{
                key: this.key,
                pn: this.page,
                ps: this.size
            }).then(data=>{
                this.list = data.list;
                this.size = data.pageSize;
                this.total = data.total;
            });
        },
        handleAddBanner(){
            this.editBanner.id = 0;
            this.editBanner.itemId = '';
            this.modal = true;
        },
        handleEditBanner(index){
            this.editBanner = Object.assign({},this.list[index]);
            this.editBanner.itemId = this.editBanner.itemId + '';
            this.changeItem(this.editBanner.itemType);
            this.modal = true;
        },
        handleBannerSubmit(){
            this.$refs['editBanner'].validate((valid) => {
                if(valid) {
                    let banner = Object.assign({},this.editBanner);
                    banner.itemId = banner.itemId || 0;
                    if(banner.itemId && banner.itemId != 0 && this.miniPath){
                        banner.miniPath = this.miniPath + banner.itemId;
                    }else {
                        banner.miniPath = '';
                    }
                    this.$http.post('/api/admin/saveBanner',banner).then(()=>{
                        this.$Message.success("操作成功");
                        this.queryBannerList();
                        this.modal = false;
                    });
                }
           });
        },
        handleDeleteBanner(index){
            this.$Modal.confirm({
              title: '删除操作',
              content: '确认删除该轮播图吗？',
              onOk: () => {
                 let banner = this.list[index];
                 this.$http.post('api/admin/deleteBanner',{id: banner.id}).then(()=>{
                    this.$Message.success("删除成功");
                    this.queryBannerList();
                 });
              }
           });
        },
        resetForm(show){
           if(!show) {
              this.$refs.editBanner.resetFields();
              this.editBanner.id = 0;
              this.editBanner.itemId = '';
              this.resetSelectPage(false,'itemSelect');
           }
        },
        resetSelectPage(isRemote,...refs){
           if(refs){
              refs.forEach((ref)=>{
                  resetSelectPage(this.$refs[ref],true);
                  isRemote && this.$refs[ref].remote();
              });
           }
       },
        handlePageChange(page){
           this.page = page;
           this.queryBannerList();
        },
        handlePageSize(size){
           this.size = size;
           this.page = 1;
           this.queryBannerList();
        },
        resultFormat(resp){
            return {
                totalRow: resp.total,
                pageNumber: resp.pageNum,
                firstPage: resp.isFirstPage,
                lastPage: resp.isLastPage,
                totalPage: resp.pages,
                pageSize: resp.pageSize,
                list: resp.list
            }
        }
    }
}
</script>