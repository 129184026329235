<template>
    <div class="banner-manager">
        
        <Card :padding="10" title="公告列表" shadow>
            <p slot="extra">
               <Button @click="handleAddBanner" type="primary">新增</Button>
            </p>
            <Table :columns="columns" border :data="list" stripe></Table>
            <Page :total="total" :current="page" :page-size="size" @on-change="handlePageChange" @on-page-size-change='handlePageSize' show-total show-elevator show-sizer class-name="bt-page"/>
        </Card>

        <Modal
            v-model="modal"
            :title="editBanner.id == 0 ? '新增' : '编辑'"
            @on-visible-change="resetForm">
            <Form ref="editBanner" :model="editBanner" :label-width="120">
                <FormItem label="内容" prop="content" :rules="[{required: true, message: '内容不能为空'},{min:1,max:20,message:'长度超出范围'}]">
                   <Input v-model.trim="editBanner.content" placeholder="请输入内容"></Input>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button type="text" size="large" @click="modal=false">取消</Button>
                <Button type="primary" size="large" @click="handleBannerSubmit">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import {renderShowImage,formatDate,resetSelectPage} from '@/utils';

export default {
    name: 'bannerManager',
    data(){
        return {
            key: '',
            list: [],
            page: 1,
            size: 20,
            total: 1,
            columns:[{
              key: 'id',
              title: 'ID',
              align: 'center'
           },{
              key: 'content',
              title: '内容',
              tooltip: true
           },{
              key: 'createtime',
              title: '创建时间',
              align: 'center',
              render: (h,{row})=>{
                  return h('span',formatDate(row.createtime,'yyyy-MM-dd hh:mm:ss'));
              }
           },{
              key: 'option',
              title: '操作',
              minWidth: 120,
              render: (h, {index}) => {
                 let data = [];
                 data.push(h('Button',{
                        props: {type: 'success',size: 'small'},
                        style: {marginRight: '5px'},
                        on: {
                            click: () => {
                                this.handleEditBanner(index);
                            }
                        }
                    }, "编辑"));
                 data.push(h('Button',{
                        props: {type: 'error',size: 'small'},
                        style: {marginRight: '5px'},
                        on: {
                            click: () => {
                                this.handleDeleteBanner(index);
                            }
                        }
                    }, "删除"));
                 return h('div', data);
              }
            }],
            modal: false,
            editBanner: {
               id: 0,
               name: '',
               path: '',
               status: 0,
               orders: 0,
               itemType: 0,
               itemId: '',
               miniPath: ''
            },
            itemName: '',
            itemUrl: '',
            itemColumns: [],
            keyField: 'id',
            showField: '',
            currentItemId: '',
            validItem: false,
            miniPath: ''
        }
    },
    created(){
        this.searchBannerList();
    },
    activated(){
        this.queryBannerList();
    },
    methods: {
        searchBannerList(){
            this.page = 1;
            this.queryBannerList();
        },
        queryBannerList(){
            this.$http.post('/api/admin/getNoticeList',{
                key: this.key,
                pn: this.page,
                ps: this.size
            }).then(data=>{
                this.list = data.list;
                this.size = data.pageSize;
                this.total = data.total;
            });
        },
        handleAddBanner(){
            this.editBanner.id = 0;
            this.modal = true;
        },
        handleEditBanner(index){
            this.editBanner = Object.assign({},this.list[index]);
            this.modal = true;
        },
        handleBannerSubmit(){
            this.$refs['editBanner'].validate((valid) => {
                if(valid) {
                    let banner = Object.assign({},this.editBanner);
                    banner.itemId = banner.itemId || 0;
                    this.$http.post('/api/admin/saveNotice',banner).then(()=>{
                        this.$Message.success("操作成功");
                        this.queryBannerList();
                        this.modal = false;
                    });
                }
           });
        },
        handleDeleteBanner(index){
            this.$Modal.confirm({
              title: '删除操作',
              content: '确认删除该公告吗？',
              onOk: () => {
                 let banner = this.list[index];
                 this.$http.post('api/admin/deleteNotice',{id: banner.id}).then(()=>{
                    this.$Message.success("删除成功");
                    this.queryBannerList();
                 });
              }
           });
        },
        resetForm(show){
           if(!show) {
              this.$refs.editBanner.resetFields();
              this.editBanner.id = 0;
              this.resetSelectPage(false,'itemSelect');
           }
        },
        resetSelectPage(isRemote,...refs){
           if(refs){
              refs.forEach((ref)=>{
                  resetSelectPage(this.$refs[ref],true);
                  isRemote && this.$refs[ref].remote();
              });
           }
       },
        handlePageChange(page){
           this.page = page;
           this.queryBannerList();
        },
        handlePageSize(size){
           this.size = size;
           this.page = 1;
           this.queryBannerList();
        },
        resultFormat(resp){
            return {
                totalRow: resp.total,
                pageNumber: resp.pageNum,
                firstPage: resp.isFirstPage,
                lastPage: resp.isLastPage,
                totalPage: resp.pages,
                pageSize: resp.pageSize,
                list: resp.list
            }
        }
    }
}
</script>